import { useEffect, useMemo, useState } from "react";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const Timer = (props) => {

    let deadline = new Date().toString()
    const parsedDeadline = useMemo(() => Date.parse(deadline), [deadline]);
    const [time, setTime] = useState(parsedDeadline - Date.now());

    useEffect(() => {
      

        const interval = setInterval(
            () =>{ 
                let amount = parsedDeadline - new Date(Date.now() - 3600000).getTime()
        
                if(amount<0){
                    amount = 0
                }
                setTime(amount)},
            1000,
        );

        return () => clearInterval(interval);
    }, []);

    if(Math.floor(time/1000 ) ==0){
        return <div className="timer">Endpoint expired</div>
    }

    return (
        <div className="timer">
          
            {`${Math.floor((time / MINUTE) % 60)}`.padStart(2, "0")}:{`${Math.floor((time / SECOND) % 60)}`.padStart(2, "0")} Remaining
            <div style={{fontSize:'18px'}}><p>Serving your json at: </p><p><a style={{ color: 'white'}} target="_blank" href={props.message}>{props.message}</a></p></div>
        </div>
    );
};