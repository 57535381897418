import './App.css';
import { useState } from 'react';
import  {Timer}  from './Timer';
import loadingGif from './loading.gif'

function App() {
  const [json, setJson] = useState('');
  const [showTimer, setShowTimer] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [url, setUrl] = useState("")
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  function countDown(){

  }

  async function checkout(){
    await  fetch('http://localhost:4242/create-checkout-session',{
    method: "POST",
    mode: "no-cors"
  });
  }

  async function serveJson(){
    window.gtag('event', 'conversion', { 'send_to': 'AW-16553187728/xjzpCIWxz6wZEJCzltU9', 'value': 1.0, 'currency': 'GBP' })

    setShowLoading(true)
    setShowTimer(false)
    setShowError(false)

    timer = null

    let data

    if(json!=''){
      try{
        data = JSON.stringify(JSON.parse(json))
      }
      catch(error){
        console.log()
        let message = error.message
        setErrorMessage(message)
        setShowLoading(false)
        setShowError(true)
        return
      }
    }  
    else{
      data = JSON.stringify(JSON.parse('{}'))
    }

    let response = await  fetch('https://servejsonnowapiapi.azure-api.net',{
    method: "POST",
    mode: "cors",
    body: data,
    headers: new Headers({
      "accept": "*/*",
      'content-type': 'application/json',
      'Access-Control-Allow-Origin':'*'
    })
  });

  const reader = response.body.getReader();


  let body = await reader.read();

  
  let utf8decoder = new TextDecoder(); 

  let str = utf8decoder.decode(body.value)


  setUrl(str)

  console.log(str)

  if(response.status == 200){
    console.log(response)
    setShowLoading(false)
    setShowTimer(true)
  }
  else
  {
    error = response.text
    console.log(response)
      setShowError(true)
  }  

  }

  let timer = null
  let loading = null
  let error = null


  if(showLoading){
    loading = <img className="loading-image" src={loadingGif}/>
  }

  if(showTimer){
    let link = "https://api.servejsonnow.com/" + url
    timer = <Timer message={link} />
  }

 
  if(showError){
    setShowLoading(false)
    error = <p style={{width: '300px', fontSize: '18px', backgroundColor:'#8B0000', padding: '5px'}}>Error: {errorMessage}</p>
  }


  let title = "{  Serve Json Now  }"


  // Add :focus and accessibility features to premium link

  return (
    <div className="App">
      <div className="App-header">
        <h1>
        {title}
        </h1> 
        <textarea
            className='inputBox'
            placeholder='{ 
              "paste your" : "json here" 
            }'
            type="text"
            value={json}
            onChange={e => setJson(e.target.value)}
         />
   
         <button 
         className='serveButton'
         onClick={x => serveJson()}>
          HOST YOUR JSON
          </button>
          <br/>
          {loading}
          {timer}
          {error}
          {/* <form action="http://localhost:4242/create-checkout-session" method="POST">
            <button type="submit" className='link-to-paid'>One Millon API calls for $1 : Explore paid options<svg xmlns="http://www.w3.org/2000/svg" fill='green' width="20" height="20"><path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z"/></svg></button>
        </form> */}
      </div>
            
    </div>
  );
}

export default App;
